* {
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Questrial', sans-serif;
}

html {
  height: 100%;
}

body > #root > div {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import '~bootstrap/dist/css/bootstrap.css';